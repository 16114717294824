<template>
  <div>
    <Modal
      v-model="modal.show"
      ref="modal"
      :mask-closable="false"
      @on-ok="ok"
      @on-cancel="cancel"
    >
      <div class="code">
        <div class="SmBg" v-show="boo">
          <div>二维码失效</div>
          <div @click="init">刷新</div>
        </div>
        <div id="qrcode" ref="qrcode" class="qrcode"></div>
        <p class="codep" v-if="!boo">
          {{ desc }},请在{{ 60 - num }}秒内扫描二维码
        </p>
      </div>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
import api from "@/util/api";
import QRCode from "qrcodejs2";
export default {
  name: "codeT",
  components: {},
  props: {
    modal: {
      default: {
        show: false,
        id: 0,
        datacode: {},
      },
    },
  },
  data() {
    return {
      timers: null,
      desc: "扫描二维码支付",
      num: 0,
      boo: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
       this.boo = false;
      document.getElementById("qrcode").innerHTML = ""; //获取生成二维码后显示的容器的ID
      this.qrCode = new QRCode("qrcode", {
        width: 200, //二维码的宽度
        height: 200, //二维码的高度
        text: this.modal.datacode.wxPrepayData, //调用weixincode()方法时后台传的code
        colorDark: "#000", //二维码颜色
        colorLight: "#fff", //二维码底色
      });
      this.getOrderstate(); //生成二维码的时候调用监听支付是否成功的方法
    },
    getOrderstate() {
      let self = this;
      // let num = this.num;
      this.num = 0;
      let orderMainNo = this.modal.datacode.orderMain.orderNumber || '';
      self.timers = setInterval(() => {
        //创建一个全局的定时器
        this.num++;
        api.orderNativePayStatus({ orderMainNo }, (res) => {
          switch (res.data) {
            // SUCCESS
            case "SUCCESS":
              // this.$router.push({ path: "/success" });
              this.modal.show = false;
              this.$Message.success("支付成功!");
              clearInterval(this.timers);
              break;
            case "NOTPAY":
              // this.desc = '正在付款,扫码支付';
              break;
          }
        });
        if (this.num == 60) {
          //这里是判断num++到60的情况下用户还没有支付则自动关闭定时器和二维码
          // this.modal.show = false;
          //
          clearInterval(this.timers);
          this.boo = true;
        }
      }, 1000);
    },
    click() {
      let orderMainNo = this.modal.datacode.orderMain.orderMainNo;
      api.orderNativePayStatus(orderMainNo, (res) => {});
    },
    ok() {
      // this.$Message.info("Clicked ok");
    },
    cancel() {
      this.modal.show = false;
      this.$Message.info("取消支付");
    },
  },
  beforeDestroy() {
    if (this.timers) {
      clearInterval(this.timers); //关闭
    }
  },
};
</script>
<style lang="less" scoped>
.code {
  width: 200px;
  height: 300px;
  margin: auto;
  // position: relative;/
  display: flex;
  align-items: center;
}
.qrcode {
  position: absolute;
}
.codep {
  width: 100%;
  text-align: center;
  margin-top: 260px;
}
.SmBg {
  position: absolute;
  top: 66px;
  left: 160px;
  width: 200px;
  height: 200px;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 1px;
  z-index: 9;
  div {
    // height:20px;
    margin-top: 40px;
  }
  div:nth-child(2) {
    width: 100px;
    height: 38px;
    background: red;
    opacity: 0.9;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 38px;
    letter-spacing: 2px;
    text-align: center;
    cursor: pointer;
    margin: 40px auto;
  }
}
</style>
