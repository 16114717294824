<template>
  <div class="AfterSalesService">
    <div class="banner">
      <img src="@/assets/images/parkBanner.png" alt="" />
    </div>
    <div class="container">
      <Tabs value="service1">
        <TabPane label="殡葬礼仪" name="service1">
          <Form
            :model="formItem"
            :label-width="100"
            ref="formItem"
            :rules="rulesForm"
          >
            <FormItem label="亲属姓名：" prop="name">
              <Input
                v-model="formItem.name"
                placeholder="请输入预约人姓名"
              ></Input>
            </FormItem>
            <FormItem label="联系方式：" prop="phone">
              <Input
                v-model="formItem.phone"
                placeholder="请输入预约人联系方式"
              ></Input>
            </FormItem>
            <!-- <FormItem label="逝者姓名：">
              <Input
                v-model="formItem.input"
                placeholder="请输入逝者姓名"
              ></Input>
            </FormItem> -->
            <FormItem label="墓穴位置：" prop="address">
              <Input
                v-model="formItem.address"
                placeholder="请输入墓穴位置"
              ></Input>
            </FormItem>
            <FormItem label="日     期：" prop="targetTime">
              <DatePicker
                type="date"
                v-model="formItem.targetTime"
                placeholder="请选择寄存日期"
              ></DatePicker>
            </FormItem>
            <FormItem label="备     注：" prop="remarks">
              <Input
                v-model="formItem.remarks"
                type="textarea"
                placeholder="请输入备注"
              ></Input>
            </FormItem>
            <FormItem label="预约项目：">
              <!-- <Input v-model="formItem.input" placeholder="请输入预约人姓名"></Input> -->
              <ul class="projectList">
                <li
                  v-for="(item, idx) in list"
                  :key="idx"
                  @click="jcClick(item)"
                  :style="jcid == item.id ? 'border:2px solid #299654;' : ''"
                >
                  <img class="projectImg" :src="item.url" />
                  <div class="projectName">{{ item.name }}</div>
                  <div class="projectPrice">
                    ¥{{ item.price }}
                    <Tooltip
                      placement="top"
                      max-width="200"
                      theme="light"
                      :content="item.descript"
                    >
                      <span>详情</span>
                      <!-- <div slot="content">
                    <p>388元鲜花套餐内容：1个花篮，1盒水果，4盘点心，祈福卡片，擦碑服务，拍摄照片及视频发送至客户微信。</p>
                  </div> -->
                    </Tooltip>
                    <s
                  </div>
                </li>
              </ul>
            </FormItem>
            <FormItem label="">
              <div class="submitBtn" @click="handleSubmit()">提交</div>
            </FormItem>
          </Form>
        </TabPane>
        <TabPane label="墓碑维修" name="service2">
          <Form
            :model="formItem1"
            :label-width="100"
            ref="formItem1"
            :rules="rulesForm1"
          >
            <FormItem label="亲属姓名：" prop="name">
              <Input
                v-model="formItem1.name"
                placeholder="请输入预约人姓名"
              ></Input>
            </FormItem>
            <FormItem label="联系方式：" prop="phone">
              <Input
                v-model="formItem1.phone"
                placeholder="请输入预约人联系方式"
              ></Input>
            </FormItem>
            <FormItem label="日     期：" prop="targetTime">
              <DatePicker
                type="date"
                v-model="formItem1.targetTime"
                placeholder="请选择寄存日期"
              ></DatePicker>
            </FormItem>
            <FormItem label="墓穴位置：" prop="area">
              <Input
                v-model="formItem1.area"
                placeholder="请输入墓穴位置"
              ></Input>
            </FormItem>
            <FormItem label="损坏图片：" prop="imgBreak">
              <Upload
                :url="formItem1.imgBreak"
                @setUrl="
                  (url) => {
                    formItem1.imgBreak = url;
                    $forceUpdate();
                  }
                "
              />
            </FormItem>
            <FormItem label="维修选项：" prop="repairTypeName">
              <CheckboxGroup v-model="formItem1.repairTypeName">
                <Checkbox :label="0" :value="0">墓碑维修</Checkbox>
                <Checkbox :label="1" :value="1">描金</Checkbox>
                <Checkbox :label="2" :value="2">更换绿植</Checkbox>
              </CheckboxGroup>
            </FormItem>
            <FormItem label="备     注：" prop="remarks">
              <Input
                v-model="formItem1.remarks"
                type="textarea"
                placeholder="请输入备注"
              ></Input>
            </FormItem>
            <FormItem label="">
              <div class="submitBtn" @click="handleSubmit1()">提交</div>
              <div class="phone">投诉电话：<span>400-100-8888</span></div>
            </FormItem>
          </Form>
        </TabPane>
        <TabPane label="服务投诉" name="service3">
          <Form
            :model="formItem2"
            :label-width="100"
            ref="formItem2"
            :rules="rulesForm2"
          >
            <FormItem label="亲属姓名：" prop="name">
              <Input
                v-model="formItem2.name"
                placeholder="请输入预约人姓名"
              ></Input>
            </FormItem>
            <FormItem label="联系方式：" prop="phone">
              <Input
                v-model="formItem2.phone"
                placeholder="请输入预约人联系方式"
              ></Input>
            </FormItem>
            <FormItem label="逝者姓名：" prop="deadName">
              <Input
                v-model="formItem2.deadName"
                placeholder="请输入逝者姓名"
              ></Input>
            </FormItem>
            <FormItem label="墓穴位置：" prop="address">
              <Input
                v-model="formItem2.address"
                placeholder="请输入墓穴位置"
              ></Input>
            </FormItem>
            <FormItem label="投诉内容：" prop="complaintContent">
              <Input
                v-model="formItem2.complaintContent"
                type="textarea"
                placeholder="请输入投诉内容"
              ></Input>
            </FormItem>
            <FormItem label="">
              <div class="submitBtn" @click="handleSubmit2()">提交</div>
              <div class="phone">投诉电话：<span>400-100-8888</span></div>
            </FormItem>
          </Form>
        </TabPane>
        <TabPane label="预约看墓" name="service4">
          <Form
            :model="formItem3"
            :label-width="100"
            ref="formItem3"
            :rules="rulesForm3"
          >
            <FormItem label="预约人数：" prop="personNumber">
              <Input
                v-model.number="formItem3.personNumber"
                placeholder="请输入预约人数"
              ></Input>
            </FormItem>
            <FormItem label="站点路线：" prop="name"  v-if="listOne.length">
              <!-- <Input
                v-model="formItem3.name"
                placeholder=""
              ></Input> -->
              <Select
                v-model="formItem3.way"
                style="width:284px;"
                placeholder="请选择站点"
              >
                <Option  v-for="(item,index) in sateList.subscribeStationSiteList" :key="index" :value="item.name">{{item.name}}</Option>
              </Select>
            </FormItem>
            <FormItem label="姓名：" prop="name">
              <Input v-model="formItem3.name" placeholder="请输入姓名"></Input>
            </FormItem>
            <FormItem label="电话：" prop="phone">
              <Input v-model.number="formItem3.phone" placeholder="请输入电话"></Input>
            </FormItem>
            <FormItem label="路线：" v-if="listOne.length">
              <Input
                v-model="formItem3.sate"
                disabled
                placeholder="请选择路线"
              ></Input>
            </FormItem>
            <FormItem label="地址：" prop="address" v-if="!listOne.length">
              <Input
                v-model="formItem3.address"
                placeholder="请填写地址"
              ></Input>
            </FormItem>
            <div class="wayPrice">
                  <div>需支付车费:</div>
                  <!-- v-if="listOne.length" -->
                  <div >￥{{ packagelist[0].price|| 0}}元</div>
            </div>
            <div class="sateList" v-for="(item,index) in listOne" :key="index"  v-if="listOne.length">
                <div><Checkbox v-model="item.boolean" @on-change="singClick(index)"><span class="sateSpan">{{item.stationName}}</span></Checkbox></div>
                <div class="sateListCon">
                    <div v-for="(item,index) in item.subscribeStationSiteList" class="sateListConChild">{{item.name}}</div>
                </div>
            </div>
            <FormItem label="">
              <div class="submitBtn" @click="handleSubmit3()">提交</div>
              <div
                class="submitBtn"
                @click="model1.show = true;model1.id = 1"
                style="margin-left: 20px"
              >
                预约记录
              </div>
            </FormItem>
          </Form>
        </TabPane>
      </Tabs>
    </div>
        <seeTomb v-if="model1.show" :modal="model1"></seeTomb>
        <qrCode :modal="model" v-if="model.show"></qrCode>
  </div>
</template>

<script>
import api from "@/util/api";
import Upload from "@/components/public/UploadT.vue";
import seeTomb from "./model/seeTomb";
import qrCode from "./model/codeT";
export default {
  components: { Upload ,seeTomb,qrCode},
  computed: {
    isRead() {
      return true;
    },
  },
  data() {
    return {
      companyId: this.$route.query.companyId || 0,
      //殡葬礼仪
      list: [],
      packagelist:[],
      single:false,
      listOne:[],
      sateList:{},
      sateIndex:null,
      formItem: {},
      jcid: null,
      jcName: null,
      model: {
        show: false,
        id: 0,
      },
      model1: {
        show: false,
        id: 0,
      },
      rulesForm: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入地址",
            trigger: "blur",
          },
        ],
        targetTime: [
          {
            required: true,
            type: "date",
            message: "请选择日期",
            trigger: "change",
          },
        ],
        remarks: [
          {
            required: true,
            message: "请输入地址",
            trigger: "blur",
          },
        ],
      },
      formItem1: {},
      rulesForm1: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        area: [
          {
            required: true,
            message: "请输入地址",
            trigger: "blur",
          },
        ],
        repairTypeName: [
          {
            type: "array",
            required: true,
            message: "维修选择",
            trigger: "change",
          },
        ],
        targetTime: [
          {
            required: true,
            type: "date",
            message: "请选择日期",
            trigger: "change",
          },
        ],
        remarks: [
          {
            required: true,
            message: "请输入地址",
            trigger: "blur",
          },
        ],
        imgBreak: [
          {
            required: true,
            message: "必填项不能为空",
            trigger: "change",
          },
        ],
      },

      formItem2: {},
      rulesForm2: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        deadName: [
          {
            required: true,
            message: "请输入逝者姓名",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入逝者姓名",
            trigger: "blur",
          },
        ],
        complaintContent: [
          {
            required: true,
            message: "请输入逝者姓名",
            trigger: "blur",
          },
        ],
      },
      formItem3: {
        name: "",
      },
      rulesForm3: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        phone: [
          {
            type:'number',
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        personNumber: [
          {
            type:'number',
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        personNumber: [
          {
            type:'number',
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    model: {
      deep: true, //深度监听设置为 true
      handler: function (newV, oldV) {
        !newV.show && this.search();
      },
    },
  },
  mounted() {
    this.search();
    this.subscribestationList();
    this.packageListSearch();
  },
  methods: {
    search() {
      api.apiOrderFuneralEtiquetteList({ companyId: this.companyId }, (res) => {
        this.list = res.data;
      });
    },
    packageListSearch(){
      let search ={companyId: this.companyId}
      api.subscribeTombPackagelist({ search }, (res) => {
        this.packagelist = res.resultList;
      });
    },
    // 站点列表
    subscribestationList(){
       api.subscribestationList({ companyId: this.companyId }, (res) => {
        console.log(res.data);
        res.data.map((item)=>{
          return item.boolean = false
        });
        this.listOne = res.data
      });
    },
    singClick(index){
      let arr = this.listOne;
      console.log(this.sateIndex,index);
      if(this.sateIndex == index){
        console.log('111');
        this.sateList = {};
        this.sateIndex =null;
        arr.map((item)=>{
          return item.boolean = false
        });
        this.listOne = arr
        this.formItem3.way = ''
      }else{
        console.log('222');
        this.sateList = this.listOne[index];
        this.sateIndex = index;
        arr.map((item)=>{
          return item.boolean = false
        });
        arr[index].boolean =  true;
        this.listOne = arr
        this.formItem3.sate = this.sateList.stationName
      }
    },
    //殡葬礼仪
    jcClick(item) {
      this.jcid = item.id;
      this.jcName = item.name;
    },
    handleSubmit() {
      if (this.jcid == null) {
        return this.$Message.info("请选择预约项目");
      }
      this.$refs.formItem.validate((valid) => {
        if (valid) {
          let formItem = { ...this.formItem };
          formItem.targetTime = new Date(formItem.targetTime).getTime();
          formItem.companyId = this.companyId;
          formItem.packageId = this.jcid;
          api.apiOrderFuneralEtiquetteAdd(formItem, (res) => {
            this.$Message.success("提交成功!");
            this.jcid = null;
            this.jcName = null;
            this.formItem = {};
          });
        } else {
          // this.$Message.error("Fail!");
        }
      });
    },
    handleSubmit1() {
      this.$refs.formItem1.validate((valid) => {
        if (valid) {
          let formItem = { ...this.formItem1 };
          formItem.targetTime = new Date(formItem.targetTime).getTime();
          formItem.companyId = this.companyId;
          console.log(formItem);
          api.repairTombAdd(formItem, (res) => {
            if (res.resCode == "0000") {
              this.$Message.success("提交成功!");
              this.formItem1 = {};
            }
          });
        } else {
          // this.$Message.error("Fail!");
        }
      });
    },
    handleSubmit2() {
      console.log(111);
      this.$refs.formItem2.validate((valid) => {
        if (valid) {
          console.log(222);
          let formItem = { ...this.formItem2 };
          formItem.companyId = this.companyId;
          console.log(333, formItem);
          api.complaintAdd(formItem, (res) => {
            console.log(444, formItem);
            this.$Message.success("提交成功!");
            this.formItem2 = {};
          });
        } else {
          // this.$Message.error("Fail!");
        }
      });
    },
    handleSubmit3() {
      this.$refs.formItem3.validate((valid) => {
        if (valid) {
          let formItem = { ...this.formItem3,startStation:this.formItem3.way,stationRemark:this.formItem3.sate,};
          formItem.companyId = this.companyId;
          console.log(333, formItem);
          api.subscribeTombAdd(formItem, (res) => {
            console.log(444, formItem);
            // this.$Message.success("提交成功!");
            this.formItem3 = {};
            api.subscribeTombPayOrder({
              orderId  : res.data.id,
            },res=>{
                // console.log('res');
              this.model.show = true;
              this.model.datacode = res.data;
            })
          });
        } else {
          // this.$Message.error("Fail!");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.AfterSalesService {
  .banner {
    height: 220px;
  }
  .container {
    background: #ffffff;
    border-radius: 2px;
    margin-top: 24px;
  }
  /deep/.ivu-tabs-bar {
    height: 65px;
    line-height: 65px;
    align-items: center;
    display: flex;
    padding-left: 24px;
    margin: 0;
    .ivu-tabs-tab {
      padding: 5px 0;
      margin-right: 32px;
    }
    /deep/.ivu-tabs-tab-active {
      color: #299654;
      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0px;
        background: #299654;
        width: 100%;
        height: 2px;
        border-radius: 1px;
      }
    }
    /deep/.ivu-tabs-ink-bar {
      background: #299654;
      padding-left: 32px;
      display: none;
    }
  }
  /deep/.ivu-tabs-tabpane {
    padding: 20px 10px;
  }
  /deep/.ivu-input,
  .ivu-date-picker {
    width: 284px;
  }
  /deep/.ivu-input-type-textarea {
    textarea {
      width: 458px;
      height: 118px;
    }
  }
  /deep/.ivu-radio-inner:after {
    background: #299654;
  }
  /deep/.ivu-radio-inner {
    border-color: #299654;
  }
  .projectList {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 161px;
      height: 222px;
      border: 1px solid #979797;
      margin-right: 21px;
      overflow: hidden;
      .projectImg {
        width: 160px;
        height: 160px;
      }
      .projectName {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #4d4d4d;
        line-height: 25px;
        margin: 4px 8px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .projectPrice {
        font-size: 24px;
        font-weight: 400;
        color: #e64747;
        line-height: 33px;
        letter-spacing: 1px;
        margin: 0 8px 0;
        display: flex;
        align-items: center;
        /deep/.ivu-tooltip {
          margin-left: auto;
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: #6085bb;
          line-height: 20px;
          cursor: pointer;
        }
      }
    }
  }
  /deep/.ivu-form-item-content {
    display: flex;
    align-content: center;
  }
  /deep/.ivu-radio-wrapper {
    margin-right: 22px;
  }
  .submitBtn {
    width: 116px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    background: #299654;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 2px;
    cursor: pointer;
    &:active {
      opacity: 0.8;
    }
  }
  .phone {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #1a1a1a;
    letter-spacing: 1px;
    span {
      color: #299654;
    }
  }
  .sateList{
    border:1px solid #888888;
    width:80%;
    margin:10px 40px;
    padding:10px;
    box-shadow: 1px 1px 1px #888888;

  }
  .sateSpan{
    margin-left:20px;
  }
  .sateListCon{
    margin-top:50px;
    display: flex;
    flex-wrap: nowrap;
  }
  .sateListConChild{
    width:60px;
    text-align: center;
    position: relative;
  }
  .sateListConChild::before{
    position: absolute;
    top:-30px;
    left:20px;
    content: '';
    width:20px;
    height:20px;
    border:4px solid green;
    border-radius:50%;
    background-color: white;
  }
    .sateListConChild:nth-child(1)::before{
    position: absolute;
    top:-30px;
    left:20px;
    content: '';
    width:20px;
    height:20px;
    border:4px solid green;
    border-radius:50%;
    background-color: orange;
  }
  .sateListConChild:nth-last-child(1)::before{
    position: absolute;
    top:-30px;
    left:20px;
    content: '';
    width:20px;
    height:20px;
    border:4px solid green;
    border-radius:50%;
    background-color: orange;
  }
  .sateListConChild:not(:nth-last-child(1))::after{
    position: absolute;
    top:-22px;
    left:40px;
    content: '';
    width:60px;
    height:4px;
    background-color: green;
  }
  .sateListConChild:not(:nth-child(1)){
    margin-left:20px;
  }
  .wayPrice{
    width: 351px;
    height: 44px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 24px;
    margin-left:100px;
    margin-bottom:20px;
  }
  .wayPrice>div:nth-child(1){
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1A1A1A;
  }
  .wayPrice>div:nth-child(2){
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #E64747;
  }
}
</style>