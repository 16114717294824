<template>
  <div>
    <Upload
      ref="upload"
      :show-upload-list="false"
      :before-upload="handleBeforeUpload"
      type="drag"
      action=""
      style="display: inline-block;width:80px;"
    >
      <div
        v-if="!url"
        style="width: 80px;height:80px;line-height: 80px;"
      >
        <Icon
          type="ios-camera"
          size="20"
        ></Icon>
      </div>
      <div
        v-else
        style="width: 80px;height:80px;line-height: 80px;"
      >
        <img
          style="width: 80px;height:80px;line-height: 80px;"
          :src="url"
        >
      </div>

    </Upload>
  </div>

</template>
<script>
import api from '@/util/api'
export default {
  name: 'upLoad',
  props: {
    url: {
      default: () => {
        return ''
      }
    },
  
  },
  data() {
    return {
      imgName: '',
      visible: false,
      bigImgSrc: ''
    }
  },

  methods: {
    handleView(src) {
      this.bigImgSrc = src
      this.visible = true
    },
    // handledownload(file) {
    //   window.open(
    //     `http://101.132.156.127:9402/api/attachment/download?id=${
    //       // this.fileId.tombDesignId
    //       file.id
    //     }&x-auth-token=${sessionStorage.getItem('token')}`,
    //     '_blank'
    //   )
    // },
    handleBeforeUpload(file) {
      if (!(Math.ceil(file.size / 1024) <= 2048)) {
        this.$Notice.warning({
          title: '超出文件大小限制',
          desc: '图片大小不能超过2M'
        })
        return false
      }
      let param = new FormData()
      param.append('file', file)
      param.append('busType ', 'img')
      api.upLoad(param, res => {
        if (res.resCode == '0000') {
          console.log(111);
          this.url = res.data.url
          this.$emit('setUrl', res.data.url)
        }
      })
      return false
    }
  },
  mounted() {
    // this.uploadList = this.$refs.upload.fileList
  }
}
</script>
<style>
.demo-upload-list {
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
