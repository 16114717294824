<template>
  <div>
    <Modal
      v-model="modal.show"
      title="预约记录"
      ok-text="提交"
      ref="modal"
      width="80"
      height="600"
      :mask-closable="false"
    >
      <!-- <Row><div>注意：您可以在微信小程序墓柏网查看您的乘车二维码</div></Row> -->
      <Row>
        <Col span="24">
          <Table border :columns="columns" :data="list" :max-height="600">
            <template slot-scope="{ row, index }" slot="roles">
              <span v-for="item in row.memberRoleList">
                {{ item.roleName }}
              </span>
            </template>
            
            <!-- <template slot-scope="{ row, index }" slot="payStatus">
             {{item.payStatus == 1 ? '已支付' : '未支付'}}
            </template> -->
          </Table>
        </Col>
      </Row>
      <Row>
        <Page
          class="mt20 fr"
          size="small"
          show-elevator
          show-sizer
          show-total
          :total="total"
          :page-size="searchForm.pageSize"
          :current.sync="searchForm.page"
          @on-change="searchList"
          @on-page-size-change="
            (pageSize) => {
              (searchForm.pageSize = pageSize), searchList();
            }
          "
        />
      </Row>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
import api from "@/util/api";
export default {
  name: "seeTomb",
  data() {
    return {
      searchForm: {
        page: 1,
        pageSize: 10,
        search: {},
      },
      columns: [
        {
          title: "用户名",
          key: "name",
        },
        {
          title: "手机号",
          key: "phone",
        },
        {
          title: "预约人数",
          key: "personNumber",
        },
        {
          title: "站点",
          key: "startStation",
        },
        {
          title: "路线",
          key: "stationRemark",
        },
        {
          title: "地址",
          key: "address",
        },
        {
          title: "时间",
          key: "createTime",
        },
        // {
        //   title: "支付类型",
        //   slot: "payStatus",
        // },
      ],
      list: [],
      total: 0,
    };
  },
  props: {
    modal: {
      default: () => {
        return {
          show: false,
        };
      },
    },
  },
  mounted() {
    this.searchList();
  },
  methods: {
    searchList() {
      api.subscribeTombList(this.searchForm, (res) => {
        res.resultList.map((v) => {
          v.createTime = this.format(v.createTime);
        });
        this.list = res.resultList || [];
        this.total = res.resultCount || 0;
      });
    },
    format(date) {
      var date = new Date(date);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return y + "-" + m + "-" + d + " " + h + ":" + minute;
    },
  },
};
</script>

<style lang="less" scoped>
.ivu-form-item {
  width: 450px;
  margin: 0 auto 24px;
}
</style>